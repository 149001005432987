import React from 'react';
import Layout from 'components/Layout';
import { /*StaticQuery, */graphql, Link } from 'gatsby';
// import { withPrefix }, Link from 'gatsby-link';
import _ from 'lodash';
import get from 'lodash/get';
import Helmet from 'react-helmet';
import SEO from 'components/SEO/SEO';
import Img from 'components/Elements/Img';
import logger from 'utils/logger';

import { Container, Row, Col } from 'reactstrap';
import { Fluid } from 'components/Containers';

import LoadingView from 'components/Loaders/LoadingView';
// import { Sticky } from 'react-sticky';
import ShareBar from 'components/CTAs/ShareBar';
import { CardContact } from 'components/Cards/Cards';

class ArticleSingle extends React.Component {
  render () {
    logger.template(__filename, '>', 'render()');
    const siteTitle = get(this, 'props.data.site.siteMetadata.title');
    const data = get(this, 'props.data');
    logger.gql(data);
    if (
      !data ||
      !data.contentfulArticle ||
      !data.allContentfulContacts
    ) { return <LoadingView />; }
    const article = data.contentfulArticle;
    const contactsData = [];
    const gutter = 8;

    data.allContentfulContacts.edges.forEach((node) => {
      const contact = node.node;
      logger.verbose(`contact`, contact);
      if (contactsData.length < 2 && contact.category.some(e => e.slug === `pr`)) {
        contactsData.push(contact);
      } else if (contactsData.length < 2 && contact.category.some(e => e.slug === `general`)) {
        contactsData.push(contact);
      }
    });
    logger.verbose(`contactsData`, contactsData);
    const bgDefault = article.thumbnail ? `white` : `indigo-900`;
    // const parsed = querystring.parse(location.search);
    // logger.debug(`querystring`, parsed);
    
    const related = article.fields.related;
    logger.debug(`related`, related);
    let relatedProductLines = [];
    let relatedProductSeries = [];
    if (Array.isArray(related)) {
      related.forEach((entry) => {
        if (entry.internal.type === `ContentfulProductSeries`) {
          relatedProductSeries.push(entry);
          relatedProductLines.push({
            name: entry.productLine.name,
            slug: entry.productLine.slug
          });
        } else if (entry.internal.type === `ContentfulProductLine`) {
          relatedProductLines.push({
            name: entry.name,
            slug: entry.slug
          });
        }
      });
    };
    relatedProductSeries = _.uniqBy(relatedProductSeries, `name`);
    relatedProductLines = _.uniqBy(relatedProductLines, `name`);

    logger.templateEnd();
    return (
      <Layout>
        <Fluid>
          <SEO
            title={article.title}
            description={article.excerpt && article.excerpt.excerpt}
            image={article.thumbnail && article.thumbnail.fixed.src}
            pathname={`/media/articles/${article.categories ? article.categories[0].slug : `c`}/${article.slug}/`}
            type={`article`}
          />
          <section className={`articles bt-1`}>
            <Container fluid>
              <Row>
                <Col md={{ size: 12, order: 2 }} lg={{ size: 7, order: 2, offset: 1 }} className={`ptb-30em prl-20em`}>
                  <div className="articleMeta">
                    <i className="icon icon-Calendar-3 mr-10em" /><time>{article.createdAt}</time>
                    {
                      article.categories && (
                        article.categories.length > 0 && article.categories.map((item) => {
                          return (
                            <span key={`categories-${item.slug}`}>
                              <span className="separator mrl-10em alpha-30">/</span>
                              <Link
                                to={item.slug ? `/media?category=${item.slug}` : '/media'}
                                className={`category color-gray-cold-400 category-${item.slug}`}
                              >
                                {item.name}
                              </Link>
                            </span>
                          );
                        })
                      )
                    }
                  </div>
                  <article>
                    <header className={`pt-20em lh-12 pb-15em`}>
                      <h1 className={`ff-base fs-25em fw-600`}>{article.title}</h1>
                    </header>
                    <main className={`pb-30em article-entry`} dangerouslySetInnerHTML={{ __html: article.content.childMarkdownRemark.html }} />
                    <aside>
                      <Row className={`pb-30em`}>
                        <Col xs={12} className={`pb-10em`}>
                          <hr className="alpha-40" />
                        </Col>
                        <Col md={3}>
                          <img src="/img/logo-square-primary.png" alt="Apantac" style={{ width: "100%", maxWidth: `180px` }} />
                        </Col>
                        <Col md={9} className={`ptb-5em prl-20em`}>
                          <h2 className={`fs-11em`}>About Apantac</h2>
                          <p>Apantac LLC is a leading designer and developer of high quality, cost effective image signal processing equipment. The Apantac product line has been specifically designed to provide users with a flexible and innovative technology solution for image processing, and signal extension and processing.</p>
                        </Col>
                      </Row>
                      <Row className={`pb-30em`}>
                        { contactsData && contactsData.map((node) => {
                          const contact = node;
                          return (
                            <Col key={`contact-${contact.id}`} md={6} className={`prl-${gutter}px pb-${gutter}px`}>
                              <CardContact {...contact} />
                            </Col>
                          );
                        })}
                      </Row>
                    </aside>
                  </article>
                </Col>
                <Col lg={3} xl={3} className={`bg-gray-cold-100 sidebar prl-0 d-none d-lg-block`} style={{ borderRight: `1px solid #f1f0f7` }}>
                  <aside className={`sidebar`}>
                    <div className={`thumbnail thumbnail-article ${!article.color && `bg-${bgDefault}`}`} style={{ height: `100%`, background: article.color }}>
                      { article.banner && (
                        <Img
                          title={article.title}
                          alt={article.title}
                          fluid={article.banner.fluid}
                          className={`img-article-banner`}
                          style={{
                            position: `absolute`,
                            top: `0`,
                            left: `0`,
                            height: `100%`,
                            width: `100%`
                          }}
                          imgStyle={{
                            top: `50%`,
                            objectFit: `cover`
                          }}
                          fadeIn
                        />
                      )}
                      { !article.banner && article.thumbnail ? (
                        <Img
                          title={article.title}
                          alt={article.title}
                          sizes={article.thumbnail.fluid}
                          className={`img-article-thumbnail`}
                          style={{
                            position: `absolute`,
                            top: `0`,
                            left: `0`,
                            height: `100%`,
                            width: `100%`
                          }}
                          imgStyle={{
                            top: `50%`,
                            transform: `translate3D(-50%,-50%,0)`,
                            left: `50%`,
                            maxWidth: `80%`,
                            objectFit: `contain`
                          }}
                          fadeIn
                        />
                      ) : (
                        article.logo ? (
                          <Img
                            title={article.title}
                            alt={article.title}
                            fluid={article.logo.fixed}
                            className={`img-article-logo`}
                            style={{
                              position: `absolute`,
                              top: `0`,
                              left: `0`,
                              height: `100%`,
                              width: `100%`
                            }}
                            imgStyle={{
                              top: `50%`,
                              left: `50%`,
                              transform: `translate3D(-50%,-50%,0)`,
                              objectFit: `contain`,
                              maxHeight: `120px`,
                              maxWidth: `250px`
                            }}
                            fadeIn
                          />
                        ) : (
                          <div style={{
                            background: `url('https://images.ctfassets.net/vd4y94r5n5vv/Qbh2RBEeys2g6mukCIoES/7ad7dbc8a8ba97472cc7e77e73a946f5/articles-default-image.png?h=250')`,
                            backgroundPosition: `center`,
                            backgroundSize: `cover`,
                            display: `block`,
                            position: `absolute`,
                            top: `0`,
                            right: `0`,
                            bottom: `0`,
                            left: `0`
                          }} />
                        )
                      )}
                    </div>
                    <div className={`mb-0`}>
                      <ShareBar
                        title={article.title}
                        summary={article.excerpt && article.excerpt.excerpt}
                        hashtags={`apantac,${article.fields.categories.toString()}`}
                      />
                    </div>
                    { related && (
                      <div className="related pt-30em">
                        <Col md={10} className={`mrl-auto`}>
                          {relatedProductLines && (
                            <ul className="uls-none related-sb pl-0 mb-20em">
                              <li><b>Related Product Lines</b></li>
                              {relatedProductLines.length > 0 && (
                                relatedProductLines.map((productLine) => {
                                  return (
                                    productLine && <li key={`article-related-productLines-${productLine.slug}`}><Link to={`/products?line=${productLine.slug}`}>{productLine.name}</Link></li>
                                  );
                                })
                              )}
                            </ul>
                          )}
                          {relatedProductSeries && (
                            <ul className="uls-none related-sb pl-0 mb-20em">
                              <li><b>Related Products</b></li>
                              {relatedProductSeries.length > 0 && (
                                relatedProductSeries.map((productSeries) => {
                                  return (
                                    productSeries && <li key={`article-related-productSeries-${productSeries.slug}`}><Link to={`/products/${productSeries.productLine.slug}/${productSeries.slug}`}>{productSeries.name}</Link></li>
                                  );
                                })
                              )}
                            </ul>
                          )}
                        </Col>
                      </div>
                    )}
                  </aside>
                </Col>
              </Row>
            </Container>
          </section>
        </Fluid>
      </Layout>
    );
  }
}

export const query = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    allContentfulCategory( filter: { contentTypes: { in: ["articles"] } } ){
      edges{
        node {
          id
          ... Category
        }
      }
    }
    contentfulArticle( slug: { eq: $slug } ){
      id
      ... Article
      fields{
        categories
        related{
          name
          slug
          productLine{
            name
            slug
          }
          internal{
            type
          }
        }
      }
    }
    allContentfulContacts{
      edges {
        node {
          id
          ... Contact
        }
      }
    }
  }
`;

export default ArticleSingle;
